import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastService } from '@bazis/shared/services/toast.service';
import { ModalService } from '@bazis/shared/services/modal.service';
import { PERSONAL_INFO_PAGE_URL } from '@app/configuration.service';

@Injectable()
export class ConfirmOrgInfoInterceptor implements HttpInterceptor {
    constructor(
        private toastService: ToastService,
        private router: Router,
        private modalService: ModalService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse && error.status === 422) {
                    const signatureErrors = error.error?.errors.filter(
                        (apiError) => apiError?.code === 'ERR_SIGN_ORG_INFO',
                    );

                    if (!signatureErrors || !signatureErrors.length) {
                        return throwError(() => error);
                    }

                    console.warn(`SIGN ORG INFO INTERCEPTOR:`, signatureErrors, error.error);
                    this.modalService.dismiss();

                    this.router.navigate([PERSONAL_INFO_PAGE_URL]);

                    this.toastService.create({
                        type: 'error',
                        titleKey: 'toast.signOrgInfoError.title',
                        messageKey: 'toast.signOrgInfoError.message',
                    });
                }
                return throwError(error);
            }),
        );
    }
}
