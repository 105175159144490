import { Pipe, PipeTransform } from '@angular/core';
import { PHONE_INPUT_TRANSFORM_FN, PHONE_MASK_SETTINGS } from '@app/configuration.service';
import { NgxMaskService } from 'ngx-mask';

@Pipe({
    name: 'phone',
})
export class PhonePipe implements PipeTransform {
    constructor(private maskService: NgxMaskService) {}

    transform(value: string, pattern = PHONE_MASK_SETTINGS.mask): string {
        if (!pattern || !value) return value;
        if (PHONE_INPUT_TRANSFORM_FN) value = PHONE_INPUT_TRANSFORM_FN(value);
        value = this.maskService.applyMask(value, pattern);
        return PHONE_MASK_SETTINGS.prefix ? `${PHONE_MASK_SETTINGS.prefix}${value}` : value;
    }
}
