import { ViewportScroller } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewEncapsulation,
} from '@angular/core';
import { UNDERCONSTRUCTION_TITLE } from '@app/configuration.service';

interface TabItem {
    id: string | number;
    title?: string;
    titleKey?: string;
    titleParams?: any;
    entityType?: string;
    routerLink?: string;
    isExactLink?: boolean;
    disabled?: boolean;
    // name иконки или src
    icon?: string;
    iconPosition?: 'start' | 'end';
    underconstruction?: boolean;
}

// возможно надо будет предусмотреть для моб. устройств логику слайда табов в строке, когда они не помещаются в строку.
// еще лучше если для свайпа под мобильные сделать директиву. Так как может понадобится во многих компонентах

@Component({
    selector: 'bazis-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['tabs.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsElement {
    @Input()
    tabList: TabItem[];

    @Input()
    selectedTab: string | number;

    @Input()
    hideByZeroCount = false;

    @Input()
    needScroll = true;

    @Output()
    selectedTabChange = new EventEmitter();

    @Input() tabTemplate: TemplateRef<any>;

    sectionUnderconstruction = UNDERCONSTRUCTION_TITLE.section;

    constructor(private viewportScroller: ViewportScroller) {}

    selectTab(tab: string | number) {
        this.selectedTab = tab;
        this.selectedTabChange.emit(this.selectedTab);

        if (!this.needScroll) return;
        // setTimeout необходим, чтобы не приходилось кликать дважды на кнопку, если передано Output значение
        // данный баг двойного клика наблюдается в FF MAC OS
        setTimeout(() => this.viewportScroller.scrollToPosition([0, 0]), 0);
    }

    swipeLeft(event) {
        // console.log('swipe left', event);
    }

    swipeRight(event) {
        // console.log('swipe right', event);
    }
}
