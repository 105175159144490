import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DOCUMENT_LINKS } from '@app/configuration.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'bazis-cookie',
    templateUrl: './cookie.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookieComponent implements OnInit {
    link = DOCUMENT_LINKS.cookiePolicy;

    showCookie = false;

    constructor(private cookieService: CookieService) {}

    ngOnInit(): void {
        this.calculateCookie();
    }

    accept() {
        this.cookieService.set('cookieAccepted', 'true');
        this.calculateCookie();
    }

    calculateCookie() {
        this.showCookie = !this.cookieService.get('cookieAccepted');
    }
}
