<ng-container *transloco="let t">
    <div
        sticky
        [stickyTop]="50"
        class="bazis-list bazis-list_checkable"
        *ngIf="list$ | async as list; else tplLoading"
    >
        <div
            *ngFor="let item of list; let i = index"
            class="bazis-item"
            [class.bazis-item_parent]="item.children"
            [class.bazis-item_invalid]="!item.isValid"
        >
            <ng-container
                [ngTemplateOutlet]="stringTpl"
                [ngTemplateOutletContext]="{ item: item, index: i }"
            ></ng-container>

            <div
                *ngIf="item.children"
                class="bazis-list"
            >
                <div
                    *ngFor="let childItem of item.children; let j = index"
                    class="bazis-item"
                >
                    <ng-container
                        [ngTemplateOutlet]="stringTpl"
                        [ngTemplateOutletContext]="{ item: childItem, index: j }"
                    ></ng-container>
                </div>
            </div>
        </div>
    </div>

    <ng-template
        #stringTpl
        let-item="item"
        let-index="index"
    >
        <bazis-step
            *ngIf="item.title || item.titleKey"
            [mute]="!item.isValid && !item.children"
            [signHidden]="item.children"
        >
            {{ item.title || t(item.titleKey, item.titleKeyParam) }}
        </bazis-step>

        <ng-container *ngIf="item.titleEntity">
            <bazis-step
                [mute]="!item.isValid && !item.children"
                [signHidden]="item.children"
            >
                <ng-container
                    *ngIf="item.titleEntity"
                    [ngTemplateOutlet]="entityTemplate"
                    [ngTemplateOutletContext]="{
                        titleEntity: item.titleEntity.titleEntity,
                        titleField: item.titleEntity.titleField,
                        entityType: item.titleEntity.entityType,
                        id: item.titleEntity.id,
                        defaultTitle: item.defaultTitle,
                        defaultTitleKey: item.defaultTitleKey,
                        defaultTitleKeyParam: item.defaultTitleKeyParam
                    }"
                ></ng-container>
            </bazis-step>
        </ng-container>

        <ng-template
            #entityTemplate
            let-titleEntity="titleEntity"
            let-titleField="titleField"
            let-entityType="entityType"
            let-id="id"
            let-defaultTitle="defaultTitle"
            let-defaultTitleKey="defaultTitleKey"
            let-defaultTitleKeyParam="defaultTitleKeyParam"
        >
            <ng-container *ngIf="id | entity : entityType | async as entity">
                <ng-container *ngIf="!entity.$snapshot[titleField]">
                    {{ defaultTitle || t(defaultTitleKey, defaultTitleKeyParam) }}
                </ng-container>

                <ng-container *ngIf="entity.$snapshot[titleField] && !titleEntity">
                    {{
                        entity.$snapshot[titleField] ||
                            defaultTitle ||
                            t(defaultTitleKey, defaultTitleKeyParam)
                    }}
                </ng-container>

                <ng-container
                    *ngIf="entity.$snapshot[titleField] && titleEntity"
                    [ngTemplateOutlet]="entityTemplate"
                    [ngTemplateOutletContext]="{
                        titleEntity: titleEntity.titleEntity,
                        titleField: titleEntity.titleField,
                        entityType: entity.$snapshot[titleField].type,
                        id: entity.$snapshot[titleField].id,
                        defaultTitle: defaultTitle,
                        defaultTitleKey: defaultTitleKey,
                        defaultTitleKeyParam: defaultTitleKeyParam
                    }"
                >
                </ng-container>
            </ng-container>
        </ng-template>

        <ng-container
            *ngIf="!item.title && !item.titleKey && (!item.titleEntity || !item.titleEntity.id)"
        >
            <bazis-step
                *ngIf="item.defaultTitle || item.defaultTitleKey"
                [mute]="!item.isValid && !item.children"
                [signHidden]="item.children"
            >
                {{ item.defaultTitle || t(item.defaultTitleKey, item.defaultTitleKeyParam) }}
            </bazis-step>
        </ng-container>
    </ng-template>
</ng-container>

<ng-template #tplLoading>
    <bazis-entity-status-list-skeleton></bazis-entity-status-list-skeleton>
</ng-template>
