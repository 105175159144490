import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '@bazis/shared/services/modal.service';
import { GalleryModalComponent } from '@bazis/shared/components/modals/gallery-modal.component';
import { EntData } from '@bazis/shared/models/srv.types';

@Component({
    selector: 'bazis-gallery',
    templateUrl: './gallery.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleryComponent implements OnDestroy, OnInit {
    @Input() imageEntities: EntData[];

    @Input() isPreview: boolean;

    constructor(private modalService: ModalService) {}

    ngOnInit(): void {
        this.isPreview = this.isPreview !== undefined && this.isPreview !== false;
    }

    ngOnDestroy(): void {}

    openGallery(gallery, index) {
        const modal = this.modalService.create({
            component: GalleryModalComponent,
            componentProperties: {
                gallery,
                startIndex: index,
            },
            modalType: 'fullscreen gallery',
            hasCloseIcon: false,
        });
    }
}
