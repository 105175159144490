import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FileType, FILE_EXTENSIONS } from '@bazis/form/models/form-element.types';
import { EntData } from '@bazis/shared/models/srv.types';
import { SignaturesComponent } from '@document/component/signatures.component';
import { ModalService } from '@bazis/shared/services/modal.service';

@Component({
    selector: 'bazis-file-inline',
    templateUrl: './file-inline.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileInlineComponent implements OnInit {
    @Input() file: EntData = null;

    @Input() fileNameKey?: string = '';

    @Input() fileNameParams?: any = null;

    @Input() hasLink: boolean = true;

    @Input() showFileSize: boolean = true;

    // знак вопроса может быть рядом с файлом
    @Input() tooltipKey: string = null;

    // параметры для tooltip
    @Input() tooltipParams: any = null;

    public fileType: FileType = 'any';

    public extentions = FILE_EXTENSIONS[this.fileType];

    public extention: string;

    public srcFile: string = '';

    constructor(private modalService: ModalService) {}

    ngOnInit(): void {
        this.srcFile = this.file.$snapshot.file_upload_url || this.file.$snapshot.file;
        const extensionArr = this.srcFile.split('.');
        const extention = extensionArr[extensionArr.length - 1];
        this.extention = extention.split('?')[0];
    }

    openSignatureDetails(documentName) {
        this.modalService.create({
            component: SignaturesComponent,
            componentProperties: {
                signatures: this.file.$snapshot.signatures,
                site: location.host,
                documentName,
            },
        });
    }
}
