<div class="bazis-gallery-single">
    <div class="bh-overhide">
        <ng-container *ngFor="let image of images; let i = index">
            <ng-container *ngIf="currentIndex === i">
                <figure
                    *ngIf="image.id | entity: image.type | async as imageEntity"
                    class="bazis-figure"
                >
                    <img [src]="imageEntity.$snapshot.file" />
                </figure>
            </ng-container>
        </ng-container>
    </div>
    <ng-container *ngIf="images.length > 1">
        <bazis-button
            fill="outline"
            color="action"
            class="bazis-gallery-single__prev"
            [disabled]="currentIndex === 0"
            (click)="currentIndex = currentIndex - 1"
        >
            <bazis-icon
                slot="icon-only"
                name="angle-small-left"
            ></bazis-icon>
        </bazis-button>
        <bazis-button
            fill="outline"
            color="action"
            class="bazis-gallery-single__next"
            [disabled]="currentIndex === images.length - 1"
            (click)="currentIndex = currentIndex + 1"
        >
            <bazis-icon
                slot="icon-only"
                name="angle-small-right"
            ></bazis-icon>
        </bazis-button>
    </ng-container>
</div>
