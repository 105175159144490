import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbsService } from '@bazis/shared/services/breadcrumbs.service';
import { MediaQueryService } from '@bazis/shared/services/media-query.service';

@Component({
    selector: 'bazis-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnDestroy, OnInit {
    list$ = this.breadcrumbs.breadcrumbs$;

    constructor(public breadcrumbs: BreadcrumbsService, public mqSrv: MediaQueryService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}
}
