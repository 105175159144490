<div class="bazis-tabs-wrapper" part="wrapper">
    <ng-container *ngFor="let tab of tabList">
        <ng-container *ngIf="tab.underconstruction">
            <button
                class="bazis-tab-btn bh-underconstruction"
                [title]="sectionUnderconstruction | transloco"
                *ngIf="!hideByZeroCount || tab.id === selectedTab || tab.titleParams?.count > 0"
            >
                <ng-container
                    [ngTemplateOutlet]="tabContent"
                    [ngTemplateOutletContext]="{
                        tab: tab
                    }"
                ></ng-container>
            </button>
        </ng-container>
        <ng-container *ngIf="!tab.underconstruction">
            <ng-container *ngIf="tab.routerLink">
                <!-- если табы ссылочные, то активность таба = активности ссылки если табы будут см -->
                <button
                    class="bazis-tab-btn"
                    [class.bazis-tab-btn--selected]="tab.id === selectedTab"
                    [routerLink]="tab.routerLink"
                    routerLinkActive="active-link"
                    [routerLinkActiveOptions]="{ exact: tab.isExactLink }"
                    *ngIf="!hideByZeroCount || tab.isExactLink || tab.titleParams?.count > 0"
                    (click)="selectTab(tab.id)"
                >
                    <ng-container
                        [ngTemplateOutlet]="tabContent"
                        [ngTemplateOutletContext]="{
                            tab: tab
                        }"
                    ></ng-container>
                </button>
            </ng-container>
            <ng-container *ngIf="!tab.routerLink">
                <!-- если таб это не ссылка, то активируем таб с текущим значением tabControl -->
                <button
                    class="bazis-tab-btn"
                    [class.bazis-tab-btn--selected]="tab.id === selectedTab"
                    *ngIf="!hideByZeroCount || tab.id === selectedTab || tab.titleParams?.count > 0"
                    (click)="selectTab(tab.id)"
                >
                    <ng-container
                        [ngTemplateOutlet]="tabContent"
                        [ngTemplateOutletContext]="{
                            tab: tab
                        }"
                    ></ng-container>
                </button>
            </ng-container>
        </ng-container>
    </ng-container>
</div>

<ng-template
    #tabContent
    let-tab="tab"
>
    <bazis-icon
        *ngIf="tab.icon"
        [icon]="tab.icon"
    ></bazis-icon>
    <span *ngIf="tab.title">
        {{ tab.title }}
    </span>
    <span *ngIf="tab.titleKey">
        {{ tab.titleKey | transloco : tab.titleParams }}
    </span>
    <span *ngIf="tab.entityType && tabTemplate">
        <ng-container
            [ngTemplateOutlet]="tabTemplate"
            [ngTemplateOutletContext]="{
                id: tab.id,
                entityType: tab.entityType,
                titleParams: tab.titleParams
            }"
        ></ng-container>
    </span>
    <bazis-badge
        *ngIf="tab.hasBadge"
        [size]="tab.badgeSize"
        part="badge"
    ></bazis-badge>
</ng-template>
