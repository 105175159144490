import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { BazisSharedModule } from '@bazis/shared/shared.module';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import {EntityItemMobileSkeletonComponent} from "@shared/components/entity-item-mobile-skeleton.component";

const DECLARATIONS = [
    EntityItemMobileSkeletonComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        TranslocoModule,
        BazisSharedModule,
        NgxMaskDirective,
        NgxMaskPipe,
    ],
    declarations: [...DECLARATIONS],
    exports: [BazisSharedModule, ...DECLARATIONS, NgxMaskDirective, NgxMaskPipe],
})
export class SharedModule {}
