import {
    ViewEncapsulation,
    Component,
    Input,
    ViewChild,
    ElementRef,
    HostListener,
    Renderer2,
    HostBinding,
    Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
    selector: 'bazis-range',
    templateUrl: './range.component.html',
    styleUrls: ['range.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class RangeElement implements ControlValueAccessor {
    @ViewChild('range') rangeEl: ElementRef;

    // Minimum integer value of the range.
    @Input() min: number = 0;

    // Maximum integer value of the range.
    @Input() max: number = 100;

    @Input() step: number = 1;

    // The name of the control, which is submitted with the form data.
    @Input() name: string;

    @Input() disabled: boolean = false;

    @Input() pin: boolean = true;

    @Input() pinDimension: string;

    value;

    onChange = (_: any) => {};

    @HostListener('input', ['$event']) public handleInput(): void {
        this.renderer.setStyle(
            this.rangeEl.nativeElement,
            '--value',
            this.rangeEl.nativeElement.value,
        );
        this.onChange(this.rangeEl.nativeElement.value);
    }

    @HostBinding('style.--value') get styleValue() {
        if (this.rangeEl) {
            return this.rangeEl.nativeElement.value;
        }
    }

    @HostBinding('style.--min') get styleMin() {
        if (this.rangeEl) {
            return this.rangeEl.nativeElement.min === '' ? '0' : this.rangeEl.nativeElement.min;
        }
    }

    @HostBinding('style.--max') get styleMax() {
        if (this.rangeEl) {
            return this.rangeEl.nativeElement.max === '' ? '100' : this.rangeEl.nativeElement.max;
        }
    }

    constructor(private renderer: Renderer2, @Self() public ngControl: NgControl) {
        ngControl.valueAccessor = this;
    }

    writeValue(value): void {
        this.value = value;
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState?(isDisabled: boolean): void {}
}
