<div
    class="bazis-modal-wrap"
    [class.bazis-modal--scrolling]="isScrollingContent"
    *transloco="let t"
>
    <div class="bazis-modal__header">
        <h1 *ngIf="!document">{{ t('document.modal.h1') }}</h1>
        <h1 *ngIf="document">{{ document.$snapshot.name }}</h1>
    </div>

    <div
        (scroll)="onContentScroll($event)"
        class="bazis-modal__content"
    >
        <bazis-document-accordeon
            [document]="document"
            [isOpen]="true"
        ></bazis-document-accordeon>

        <!-- grey block text -->
        <div>{{ t('document.modal.checkDescription') }}</div>

        <!-- old signatures description -->
        <p
            class="bh-margin-bottom"
            coef="9"
        >
            <span
                [innerHTML]="
                    t('document.modal.description', {
                        documentName: document?.$snapshot?.name || documentName
                    })
                "
            ></span>
            <a [href]="'https://' + site">{{ site }}</a>
        </p>

        <div class="bazis-list sl-list_e-sign">
            <div
                class="bazis-item"
                *ngFor="let signatory of signInfo.$snapshot.signatories"
            >
                <app-signature [signatureId]="signatory.signature?.id"></app-signature>
            </div>
        </div>
    </div>
</div>
