import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Observable, retry, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const RETRY_MAX_ATTEMPTS = 3;
const RETRY_DELAY = 1000;
const RETRY_ERROR_STATUS = 504;

@Injectable()
export class HttpTimeoutInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse && error.status === RETRY_ERROR_STATUS) {
                    return next.handle(request).pipe(
                        retry({
                            delay: RETRY_DELAY,
                            count: RETRY_MAX_ATTEMPTS,
                            resetOnSuccess: false,
                        }),
                    );
                }
                return throwError(error);
            }),
        );
    }
}
