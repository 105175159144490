import {
    ChangeDetectionStrategy,
    Component,
    ComponentRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ModalService } from '@bazis/shared/services/modal.service';
import { ModalDirective } from '@bazis/shared/directives/modal.directive';
import { Modal } from '@bazis/shared/models/modal.types';

@Component({
    selector: 'bazis-modal',
    templateUrl: './modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements OnDestroy, OnInit {
    @Input()
    modal: Modal;

    @ViewChild(ModalDirective, { static: true }) componentHost!: ModalDirective;

    @HostListener('document:keydown.escape') onKeydownHandler() {
        if (this.modal.options.keyboardClose) {
            this.close();
        }
    }

    componentRef: ComponentRef<any>;

    isScrollingContent: boolean = false;

    constructor(public modalService: ModalService) {}

    ngOnInit(): void {
        this.loadComponent();
    }

    ngOnDestroy(): void {}

    close() {
        if (this.componentRef.instance.close) {
            this.componentRef.instance.close();
        } else {
            this.modalService.dismiss(null, this.modal);
        }
    }

    loadComponent() {
        const component = this.modal.options.component;
        const viewContainerRef = this.componentHost.viewContainerRef;
        viewContainerRef.clear();
        this.componentRef = viewContainerRef.createComponent(component);
        if (this.modal.options.componentProperties) {
            Object.keys(this.modal.options.componentProperties).forEach((key) => {
                this.componentRef.instance[key] = this.modal.options.componentProperties[key];
            });
        }
    }

    closeFromBackdrop() {
        if (this.modal.options.backdropClose) {
            this.close();
        } else return null;
    }

    onContentScroll(ev) {
        const scrollPosition = ev.target.scrollTop;
        this.isScrollingContent = scrollPosition > 100 ? true : false;
    }
}
