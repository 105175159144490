import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastService } from '@bazis/shared/services/toast.service';
import { AuthService } from '@bazis/shared/services/auth.service';
import { AUTH_PAGE_URL } from '@app/configuration.service';

const API_AUTH_PATH = 'authing';
export const API_AUTH_USER_PATH = 'authing/user';
const SCHEMAS = ['schema_update', 'schema_transit', 'schema_retrieve', 'schema_create'];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private toastService: ToastService,
        private authService: AuthService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error) => {
                if (
                    (error.status === 401 || error.status === 403) &&
                    error instanceof HttpErrorResponse &&
                    error.url.indexOf(API_AUTH_USER_PATH) > -1
                ) {
                    if (location.pathname !== '/') {
                        if (error.status === 403) {
                            this.router.navigate(['/access-denied'], {
                                replaceUrl: true,
                            });
                        } else if (!location.pathname.includes('/login')) {
                            this.authService.redirectToHomePage();
                        }
                    }
                    this.toastService.create({
                        type: 'error',
                        titleKey: 'toast.userPermissionError.title',
                        messageKey: 'toast.userPermissionError.message',
                    });
                    return throwError(error);
                }
                if (
                    (error.status === 401 || error.status === 403) &&
                    error instanceof HttpErrorResponse &&
                    error.url.indexOf(API_AUTH_PATH) === -1 &&
                    this.router.url !== AUTH_PAGE_URL
                ) {
                    if (error.status === 401) {
                        this.authService.logout();
                        this.toastService.create({
                            type: 'error',
                            titleKey: 'toast.authError.title',
                            messageKey: 'toast.authError.message',
                        });
                    }
                    if (error.status === 403) {
                        if (SCHEMAS.findIndex((schema) => request.url.indexOf(schema) > -1) > -1) {
                            return of(new HttpResponse({ body: { error: 403 } }));
                        }

                        // if user had token and token is expired - clear token, redirect to login page
                        if (error.url.indexOf('/auth/') > -1) {
                            this.authService.resetToken();
                            this.authService.calculateToken();
                            this.authService.redirectToLoginWithReturnPath();
                            return of(null);
                        }

                        this.router.navigate(['/access-denied'], {
                            replaceUrl: true,
                        });
                        this.toastService.create({
                            type: 'error',
                            titleKey: 'toast.requestPermissionError.title',
                            messageKey: 'toast.requestPermissionError.message',
                            messageParams: { request: request.url },
                        });
                    }
                    return of(null);
                }

                return throwError(error);
            }),
        );
    }
}
