import { Injectable } from '@angular/core';
import { from, Observable, of, switchMap, throwError, timer } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { mergeMap, catchError, map, tap, take, filter } from 'rxjs/operators';
import {
    SrvResponse,
    EntData,
    EntList,
    SrvSchema,
    SchemaType,
    EntSchema,
    EntTransitData,
    EntDocumentSettings,
    SrvEntData,
    GroupRequestItem,
} from '@bazis/shared/models/srv.types';
import { ToastService } from '@bazis/shared/services/toast.service';
import { API_AUTH_USER_PATH } from '@bazis/interceptors/auth.interceptor';
import { buildFilterStr, fileDownloaderFn } from '@bazis/utils';
import { API_URL } from '@app/configuration.service';
import { TranslocoService } from '@ngneat/transloco';

export const HEADERS = new HttpHeaders()
    .set('Content-Type', 'application/vnd.api+json')
    .set('Accept', 'application/vnd.api+json');

@Injectable({
    providedIn: 'root',
})
export class SrvService {
    constructor(
        private http: HttpClient,
        private toast: ToastService,
        private translocoService: TranslocoService,
    ) {
        // @ts-ignore
        if (window) window.srv = this;
        console.warn('[SRV][INIT]', this);
    }

    fetchEntity$<T = EntData>(
        entityType: string,
        entid: string,
        include: string[] = [],
    ): Observable<T> {
        return this.commonEntRequest$<T>('get', {
            entityType,
            entid,
            include,
        });
    }

    createEntity$<T = EntData>(entityType, srvData, include = []): Observable<T> {
        return this.commonEntRequest$<T>('post', {
            entityType,
            data: srvData,
            include,
        });
    }

    saveEntity$<T = EntData>(entityType, entid, srvData, include = []): Observable<T> {
        return this.commonEntRequest$<T>('patch', {
            entityType,
            entid,
            data: srvData,
            include,
        });
    }

    deleteEntity$<T = EntData>(entityType, entid): Observable<T> {
        return this.commonEntRequest$<T>('delete', {
            entityType,
            entid,
        });
    }

    cancelEntityDocumentSignings$<T = EntData>(
        entityType,
        entid,
        contextLabel = null,
    ): Observable<T> {
        return this.commonEntRequest$<T>('post', {
            entityType,
            entid,
            suffix: 'document_signing_cancel' + (contextLabel ? '/' + contextLabel : ''),
        });
    }

    fetchEntityDocument$<T = EntData>(
        entityType,
        entid,
        contextLabel = null,
        data = {},
    ): Observable<T> {
        return this.commonEntRequest$<T>('post', {
            entityType,
            entid,
            suffix: 'document_signing' + (contextLabel ? '/' + contextLabel : ''),
            data,
        });
    }

    // TODO - move this method into sw main app
    setPriority$<T = EntData>(entityType, entid, value, priority): Observable<T> {
        return this.commonEntRequest$<T>(value ? 'post' : 'delete', {
            entityType,
            entid,
            suffix: value ? 'add_priority' : 'delete_priority',
            params: { priority },
        });
    }

    fetchAllEntities$<T = EntList>(entityType, suffix = '', params = null): Observable<T> {
        return this.commonEntRequest$<T>('get', {
            entityType,
            params,
            suffix,
        });
    }

    fetchPage$<T = EntList>(
        entityType,
        offset,
        limit,
        params: { [key: string]: string | string[] } = null,
        meta = [],
    ): Observable<T> {
        return this.commonEntRequest$<T>('get', {
            entityType,
            offset,
            limit,
            params,
            meta,
        });
    }

    fetchPortion$<T = EntList>(
        entityType,
        suffix,
        offset,
        limit,
        search,
        params,
        meta = [],
    ): Observable<T> {
        return this.commonEntRequest$<T>('get', {
            entityType,
            suffix,
            offset,
            limit,
            search,
            params,
            meta,
        });
    }

    public fetchSchema$(
        schemaType: SchemaType,
        entityType: string,
        entid: string = '',
        include: string[] = [],
    ): Observable<EntSchema> {
        let endpointKey = entityType.split('.').join('/');

        const urlApi = API_URL;

        let url = entid ? `${urlApi}/${endpointKey}/${entid}/` : `${urlApi}/${endpointKey}/`;
        url += `${schemaType}/`;
        const paramsObj: any = {};
        if (include) {
            paramsObj.include = include.join(',');
        }
        const params = new HttpParams({ fromObject: paramsObj });
        return this.http.get<SrvSchema>(url, { headers: HEADERS, params }).pipe(
            map((response: SrvSchema) => {
                // console.log('[SRV][RESPONSE]:', response);
                if (response.error === 403) {
                    // console.log('[SRV][RESPONSE][ERROR]:', response);
                    return response;
                }
                const definitions = response.definitions;
                const getKey = (key) => key.split('/').slice(-1)[0];
                const getDefinitionKey = (property) => {
                    // TODO: multiple allFor
                    return property.$ref ? getKey(property.$ref) : getKey(property.allOf[0].$ref);
                };
                const generateRelationships = (properties) => {
                    const relationships: any = {};
                    for (let key in properties) {
                        relationships[key] = {
                            ...properties[key],
                        };
                        delete relationships[key].allOf;
                        let definitionKey = getDefinitionKey(properties[key]);
                        let propertyObject = definitions[definitionKey].properties;
                        if (propertyObject?.data?.type === 'array') {
                            definitionKey = getDefinitionKey(propertyObject.data.items);
                            relationships[key].type = 'array';
                        } else {
                            definitionKey = getDefinitionKey(propertyObject.data);
                            relationships[key].type = 'object';
                        }
                        propertyObject = definitions[definitionKey].properties;
                        relationships[key].entityType = propertyObject.type.default;
                    }
                    return relationships;
                };

                const actionToschemaTypeMap = {
                    add: 'schema_create',
                    change: 'schema_update',
                    transit: 'schema_transit',
                    view: 'schema_retrieve',
                };

                const generateSchema = (properties, schema) => {
                    properties.forEach((allOfItem) => {
                        const rootKey = getKey(allOfItem.$ref);

                        if (definitions[rootKey].properties.attributes.$ref) {
                            definitions[rootKey].properties.attributes.allOf = [
                                definitions[rootKey].properties.attributes,
                            ];
                        }

                        if (!schema.id && definitions[rootKey].properties.id?.default) {
                            schema.id = definitions[rootKey].properties.id?.default;
                        }
                        if (!schema.entityType && definitions[rootKey].properties.type?.default) {
                            schema.entityType = definitions[rootKey].properties.type?.default;
                        }
                        if (!schema.schemaType && definitions[rootKey].properties.action?.default) {
                            schema.schemaType =
                                actionToschemaTypeMap[
                                    definitions[rootKey].properties.action.default
                                ];
                        }

                        definitions[rootKey].properties.attributes.allOf.forEach(
                            (allOfAttribute) => {
                                const attributesKey = getKey(allOfAttribute.$ref);

                                schema.attributes = {
                                    ...schema.attributes,
                                    ...definitions[attributesKey].properties,
                                };
                                schema.required = [
                                    ...schema.required,
                                    ...(definitions[attributesKey].required || []),
                                ];
                            },
                        );

                        if (definitions[rootKey].properties.relationships.$ref) {
                            definitions[rootKey].properties.relationships.allOf = [
                                definitions[rootKey].properties.relationships,
                            ];
                        }
                        definitions[rootKey].properties.relationships.allOf.forEach(
                            (allOfRelationship) => {
                                const relationshipsKey = getKey(allOfRelationship.$ref);
                                schema.required = [
                                    ...schema.required,
                                    ...(definitions[relationshipsKey].required || []),
                                ];
                                schema.relationships = {
                                    ...schema.relationships,
                                    ...generateRelationships(
                                        definitions[relationshipsKey].properties,
                                    ),
                                };
                            },
                        );
                    });
                    return schema;
                };

                let schema: any = {
                    entityType: entityType,
                    id: entid,
                    attributes: {},
                    relationships: {},
                    schemaType: schemaType,
                    required: [],
                    included: [],
                };
                if (response.properties.data.$ref) {
                    response.properties.data.allOf = [response.properties.data];
                }
                const properties = response.properties.data.allOf || [
                    response.properties.data.items,
                ];
                schema = generateSchema(properties, schema);
                if (response.properties.included) {
                    let includedItems = response.properties.included.items;
                    includedItems = includedItems.anyOf ? includedItems.anyOf : [includedItems];
                    includedItems.forEach((includedItem) => {
                        schema.included.push(
                            generateSchema([includedItem], {
                                attributes: {},
                                relationships: {},
                                required: [],
                            }),
                        );
                    });
                }
                // console.log('schema in front format', schema);
                return schema;
            }),
        );
    }

    private commonEntRequest$<T = EntData>(
        method: 'post' | 'get' | 'delete' | 'put' | 'patch' | 'schema',
        o: {
            entityType: string;
            entid?: any;
            data?: any;
            limit?: number;
            offset?: number;
            search?: string;
            params?: { [key: string]: string | string[] };
            urlApi?: string;
            suffix?: string;
            meta?: string[];
            include?: string[];
        },
    ): Observable<T> {
        let url = this.generateBasicUrl(o.entityType, o.entid, o.suffix, o.urlApi);

        let meta = o.meta || [];
        // если есть намек на лист, то нужна в мета pagination
        if (o.offset !== undefined || o.limit !== undefined) {
            meta.push('pagination');
        }

        // если получаем сущность не классификатор, то нужен в мета state_actions
        if (
            o.entityType &&
            (o.entid || method === 'post') &&
            o.entityType.indexOf('classifier.') === -1
        ) {
            meta.push('state_actions');
            meta.push('crud_actions');
        }

        const paramsObj = o.params || {};
        if (o.offset !== undefined) {
            paramsObj['page[offset]'] = String(o.offset);
        }
        if (o.limit !== undefined) {
            paramsObj['page[limit]'] = String(o.limit);
        }
        if (o.search !== undefined) {
            paramsObj.search = String(o.search);
        }
        if (meta.length > 0) {
            paramsObj.meta = meta.join(',');
        }
        if (o.include && o.include.length > 0) {
            paramsObj.include = o.include.join(',');
        }

        const params = new HttpParams({ fromObject: paramsObj });

        const options = { headers: HEADERS, params };
        return of(o.entityType).pipe(
            mergeMap(() => {
                switch (method) {
                    case 'get':
                        return this.http.get<SrvResponse>(url, options);
                    case 'put':
                        return this.http.put<SrvResponse>(url, o.data, options);
                    case 'post':
                        return this.http.post<SrvResponse>(url, o.data, options);
                    case 'patch':
                        return this.http.patch<SrvResponse>(url, o.data, options);
                    case 'delete':
                        return this.http.delete<SrvResponse>(url, options);
                    case 'schema':
                        return this.http.get(url, options);
                    default:
                        console.warn(`An unknown server request method: ${method}`);
                        return of(null);
                }
            }),
            map((response: SrvResponse) => {
                //console.log('[SRV][RESPONSE]:', response, '[WITH]:', o);
                if (response) {
                    if (response.data) {
                        let result: any | any[] = response.data;
                        result =
                            result instanceof Array
                                ? this.refineList(response, o)
                                : this.makeupEntity(response.data as SrvEntData);

                        result = this.addMetaInfo(result, response.meta);
                        if (response.included && response.included.map) {
                            result.included = response.included.map((includedItem) =>
                                this.makeupEntity(includedItem),
                            );
                        }
                        return result;
                    } else return response;
                } else return response;
            }),
            catchError((e) => {
                if (
                    (e?.error?.url && e.error.url.indexOf(API_AUTH_USER_PATH) > -1) ||
                    e.status === 409
                ) {
                    return throwError(e);
                }
                console.log('[SRV][ERROR]', e);

                let message = this.generateErrorMessage(e);
                this.toast.create({
                    titleKey: 'toast.apiError.title',
                    messageKey: 'toast.apiError.message',
                    messageParams: { message },
                    type: 'error',
                });
                return throwError(e);
            }),
        );
    }

    private refineList(response, o): EntList {
        const data = response.data;
        let result = {
            type: o.entityType,
            list: data.map((ent) => this.makeupEntity(ent)),
        };
        return result;
    }

    private addMetaInfo(result, meta) {
        if (!meta) {
            return result;
        }
        result.$meta = meta;
        if (meta.state_actions) {
            const actionSigning = meta.state_actions.find(
                (action) => action.code === 'ACTION_DOCUMENT_SIGNING' && !action.restricts,
            );
            const actionSign = meta.state_actions.find(
                (action) => action.code === 'ACTION_SIGN' && !action.restricts,
            );
            result.$canSign = !!actionSigning && !!actionSign;
            if (result.$canSign) {
                const sign = actionSigning || actionSign;
                const urlParts = sign.endpoint.url.split('/');
                const contextPart = urlParts[urlParts.length - 2];
                result.$signContext = contextPart !== 'sign' ? contextPart : null;
            }

            const actionTransits = meta.state_actions.filter(
                (action) =>
                    action.code === 'ACTION_TRANSIT' ||
                    (Array.isArray(action) &&
                        action.filter((v) => v.code === 'ACTION_TRANSIT').length === action.length),
            );

            result.$transits = actionTransits
                .map((current) =>
                    Array.isArray(current)
                        ? current.map((v) => v.endpoint?.body.properties?.transit?.default)
                        : [current.endpoint?.body.properties?.transit?.default],
                )
                .filter((v) => !!v);

            result.$transitMap = actionTransits
                .reduce((acc, current) => {
                    if (Array.isArray(current)) {
                        acc = acc.concat(current);
                    } else {
                        acc.push(current);
                    }
                    return acc;
                }, [])
                .reduce((acc, current) => {
                    const transit = current.endpoint.body.properties;
                    const definitions = current.endpoint.body.definitions;
                    const criticalRestricts =
                        current.restricts?.filter(
                            (v) =>
                                v.code !== 'USER_SIGNATURE_REQUIRED' &&
                                v.code !== 'TRANSIT_RELATED_REQUIRED',
                        ) || [];
                    const needMySign =
                        current.restricts?.filter((v) => v.code === 'USER_SIGNATURE_REQUIRED')
                            .length > 0;
                    const needOtherSign =
                        current.restricts?.filter((v) => v.code === 'SIGNATURE_REQUIRED').length >
                        0;
                    let payload = [];
                    let payloadMap = {};
                    const addToMap = (initialRef, mapToAdd: any) => {
                        const refArray = initialRef.split('/');
                        const ref = refArray[refArray.length - 1];
                        const payloadProperties = definitions[ref].properties;
                        const requiredFields = definitions[ref].required || [];

                        Object.keys(payloadProperties).forEach((property) => {
                            if (payloadProperties[property].$ref) {
                                mapToAdd[property] = {};
                                addToMap(payloadProperties[property].$ref, mapToAdd[property]);
                                return;
                            }
                            let component = 'input';
                            if (payloadProperties[property].extentions) {
                                component = 'file';
                            } else if (payloadProperties[property].enum) {
                                component = 'options';
                            }

                            payload.push(property);
                            mapToAdd[property] = {
                                ...payloadProperties[property],
                                required: requiredFields.indexOf(property) > -1,
                                component,
                            };

                            if (payloadProperties[property].enumDict) {
                                const optionList = [];
                                Object.keys(payloadProperties[property].enumDict).forEach(
                                    (enumItem) => {
                                        optionList.push({
                                            id: enumItem,
                                            name: payloadProperties[property].enumDict[enumItem],
                                        });
                                    },
                                );
                                mapToAdd[property].optionList = optionList;
                                mapToAdd[property].isMultiple =
                                    mapToAdd[property].type !== 'string';
                            }
                        });
                    };

                    if (transit.payload.$ref) {
                        addToMap(transit.payload.$ref, payloadMap);
                    }
                    acc[transit.transit.default] = {
                        id: transit.transit.default,
                        url: current.endpoint.url,
                        isAllowed: !criticalRestricts.length,
                        restricts: current.restricts,
                        hint: current.hint,
                        needMySign,
                        needOtherSign,
                        showSignButtonInsteadOfTransit: needMySign && needOtherSign,
                        restrictsToDisplay: criticalRestricts,
                        payload,
                        payloadMap: payload.length > 0 ? payloadMap : null,
                    };
                    return acc;
                }, {});
        }
        if (meta.for_change) {
            result.list = result.list.map((entity) => ({
                ...entity,
                $canChange: meta.for_change.indexOf(entity.id) > -1,
                $canView: true,
            }));
        }
        if (meta.for_delete) {
            result.list = result.list.map((entity) => ({
                ...entity,
                $canDelete: meta.for_delete.indexOf(entity.id) > -1,
            }));
        }
        if (meta.for_create) {
            result.$canAdd = meta.for_create;
        }
        if (meta.crud_actions) {
            const crudMap = {
                view: '$canView',
                add: '$canAdd',
                delete: '$canDelete',
                change: '$canChange',
            };
            Object.keys(crudMap).forEach((crudAction) => {
                result[crudMap[crudAction]] = meta.crud_actions.indexOf(crudAction) > -1;
            });
        }

        return result;
    }

    public makeupEntity(ent: SrvEntData): EntData {
        let entity: EntData = {
            type: ent.type,
            id: ent.id,
            $snapshot: {
                id: ent.id,
                type: ent.type,
                ...ent.attributes,
            },
        };

        if (ent.relationships) {
            Object.keys(ent.relationships).forEach((propKey) => {
                entity.$snapshot[propKey] = ent.relationships[propKey].data;
            });
        }
        return entity;
    }

    public signEntity$(
        settings: EntDocumentSettings,
        signature,
        signBodyPayload = null,
        urlApi = '',
    ) {
        let url = this.generateBasicUrl(
            settings.entityType,
            settings.entityId,
            'sign' + (settings.contextLabel ? '/' + settings.contextLabel : ''),
            urlApi,
        );
        const signatureParams = { ...signBodyPayload, signature };
        return this.http.post<SrvResponse>(url, signatureParams, { headers: HEADERS }).pipe(
            catchError((e) => {
                console.log('[SRV][ERROR]', e);
                return throwError(e);
            }),
        );
    }

    public signEntities$(
        signSettings: { settings: EntDocumentSettings; signature; signBodyPayload }[],
        urlApi = '',
    ) {
        const endpoints = signSettings.map((setting) => {
            return {
                method: 'POST',
                endpoint: this.generateBasicUrl(
                    setting.settings.entityType,
                    setting.settings.entityId,
                    'sign' +
                        (setting.settings.contextLabel ? '/' + setting.settings.contextLabel : ''),
                    urlApi,
                    true,
                ),
                body: {
                    ...setting.signBodyPayload,
                    signature: setting.signature,
                },
            };
        });
        return this.http.post(`${API_URL}/bulk/`, endpoints).pipe(
            catchError((error) => {
                this.toast.create({
                    titleKey: 'toast.apiError.title',
                    message: this.generateErrorMessage(error),
                    type: 'error',
                });
                throw error;
            }),
        );
    }

    public transitEntity$(
        settings: {
            entityType?: string;
            entityId?: string;
            url?: string;
            transitParams: EntTransitData;
            urlApi?: string;
        }[],
    ) {
        const generateTransitUrl = (params, addLangParam = false) => {
            if (!params.url && params.entityType && params.entityId) {
                return this.generateBasicUrl(
                    params.entityType,
                    params.entityId,
                    'transit',
                    params.urlApi,
                    addLangParam,
                );
            }
            if (!addLangParam) return params.url;

            const urlParts = params.url.split('?');
            const getParts = urlParts.length > 1 ? urlParts[1].split('&') : [];
            getParts.push(this._getLangParam());
            return `${urlParts[0]}?${getParts.join('&')}`;
        };

        if (settings.length === 1) {
            return this.http.post<SrvResponse>(
                generateTransitUrl(settings[0]),
                settings[0].transitParams,
                { headers: HEADERS },
            );
        }

        const endpoints = settings.map((setting) => {
            return {
                method: 'POST',
                endpoint: generateTransitUrl(setting, true),
                body: {
                    ...setting.transitParams,
                },
            };
        });
        return this.http.post(`${API_URL}/bulk/`, endpoints);
    }

    private generateBasicUrl(
        entityType: string,
        entityId: string = '',
        suffix: string = '',
        urlApi = '',
        addLangParam = false,
    ): string {
        const endpointKey = entityType.split('.').join('/');
        const suffixKey = suffix ? suffix + '/' : '';
        urlApi = urlApi || API_URL;
        let url = entityId
            ? `${urlApi}/${endpointKey}/${entityId}/${suffixKey}`
            : `${urlApi}/${endpointKey}/${suffixKey}`;
        if (addLangParam) url += `?${this._getLangParam()}`;
        return url;
    }

    // for requests with not typical content-type format
    public sendFormRequest$<T = any>(
        endpoint: string,
        params: any = {},
        processResponseAsEntity: boolean = false,
        method: 'post' | 'put' | 'patch' = 'post',
        header: string = 'application/json',
        urlApi: string = '',
    ): Observable<T> {
        return this.commonFormRequest$(method, {
            endpoint,
            params,
            header,
            urlApi,
            processResponseAsEntity,
        });
    }

    // for requests with not typical content-type format
    public downloadFile$(
        endpoint: string,
        fileName: string,
        params = {},
        getParams = {},
        urlApi: string = '',
    ) {
        let url = `${urlApi || API_URL}/${endpoint}/`;
        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json'),
            responseType: 'blob' as 'json',
            params: new HttpParams({ fromObject: getParams || {} }),
        };
        return this.http.post(url, params, options).pipe(
            map((blob: any) => {
                fileDownloaderFn(window.URL.createObjectURL(blob), fileName);
                return blob;
            }),
            catchError((error) => {
                return from(error.error.text()).pipe(
                    switchMap((errorText: string) => {
                        const formattedError = {
                            ...error,
                            error: JSON.parse(errorText),
                        };
                        this.toast.create({
                            titleKey: 'toast.fileReceiveError.title',
                            messageKey: 'toast.fileReceiveError.message',
                            messageParams: {
                                message: this.generateErrorMessage(formattedError),
                            },
                            type: 'error',
                        });
                        return throwError(formattedError);
                    }),
                );
            }),
        );
    }

    public getFileData$(endpoint: string, urlApi: string = '') {
        let url = `${urlApi || API_URL}/${endpoint}/`;

        const options = {
            headers: new HttpHeaders().set('Content-Type', 'application/json'),
            responseType: 'blob' as 'json',
        };

        return this.http.get(url, options).pipe(
            catchError((error) => {
                this.toast.create({
                    titleKey: 'toast.fileReceiveError.title',
                    messageKey: 'toast.fileReceiveError.message',
                    messageParams: {
                        message: this.generateErrorMessage(error),
                    },
                    type: 'error',
                });
                return of(error);
            }),
        );
    }

    private commonFormRequest$<T = any>(
        method: 'post' | 'put' | 'patch',
        o: {
            endpoint: string;
            params?: any;
            urlApi?: string;
            header: string;
            processResponseAsEntity: boolean;
        },
    ): Observable<T> {
        const urlApi = o.urlApi || API_URL;
        let url = `${urlApi}/${o.endpoint}/`;

        const options = {
            headers: new HttpHeaders().set(
                'Content-Type',
                o.header || 'application/x-www-form-urlencoded',
            ),
        };

        if (o.params instanceof Object && o.header === 'application/x-www-form-urlencoded') {
            let body = new URLSearchParams();
            for (let key in o.params) {
                body.set(key, o.params[key]);
            }
            return this.http[method]<any>(url, body, options);
        }

        return this.http[method]<any>(url, o.params, options).pipe(
            map((response) => {
                if (!o.processResponseAsEntity) return response;

                let result = this.makeupEntity(response.data as SrvEntData);
                result = this.addMetaInfo(result, response.meta);
                return result;
            }),
        );
    }

    commonGetRequest(endpoint, params: any = null, urlApi = '') {
        let url = `${urlApi || API_URL}/${endpoint}/`;
        const options = { headers: HEADERS, params };
        return this.http.get<any>(url, options);
    }

    countRequest(
        settings: {
            entityType?: string;
            filters?: any;
            filterParams?: any;
            params?: any;
            url?: string;
            count?: number;
        }[],
    ) {
        settings = settings.map((setting) => {
            let endpoint = setting.entityType
                ? this.generateBasicUrl(setting.entityType, '', '', API_URL)
                : API_URL + setting.url;

            const paramsObj: any =
                setting.filters || setting.params
                    ? {
                          filter: buildFilterStr({ ...setting.filters }, setting.filterParams),
                          ...setting.params,
                      }
                    : {};

            Object.keys(paramsObj).forEach((key, index) => {
                if (index === 0) {
                    endpoint += '?';
                }
                if (index > 0) {
                    endpoint += '&';
                }
                endpoint += `${key}=${encodeURIComponent(paramsObj[key])}`;
            });

            return {
                ...setting,
                url: endpoint,
            };
        });
        const url = this.generateBasicUrl('analytic.count');
        return this.http
            .post(
                url,
                settings.map((v) => v.url),
            )
            .pipe(
                map((result: any) => {
                    return settings.map((setting) => {
                        return {
                            ...setting,
                            count: result[setting.url],
                        };
                    });
                }),
                catchError((e) => {
                    return of(
                        settings.map((setting) => {
                            return {
                                ...setting,
                                count: 0,
                            };
                        }),
                    );
                }),
            );
    }

    groupRequest(params: GroupRequestItem[]) {
        const endpoints = params.map((requestItem) => {
            let endpoint = this.generateBasicUrl(
                requestItem.entityType,
                requestItem.entityId,
                '',
                API_URL,
            );
            const meta = requestItem.meta || [];
            // если есть намек на лист, то нужна в мета pagination
            if (requestItem.requestType === 'list') {
                meta.push('pagination');
            }

            const paramsObj: any = { ...requestItem.params } || {};
            if (requestItem.offset !== undefined) {
                paramsObj['page[offset]'] = String(requestItem.offset);
            }
            if (requestItem.limit !== undefined) {
                paramsObj['page[limit]'] = String(requestItem.limit);
            }
            if (requestItem.sort !== undefined) {
                paramsObj.sort = requestItem.sort;
            }
            if (meta.length > 0) {
                paramsObj.meta = meta.join(',');
            }

            endpoint += `?${this._getLangParam()}`;

            Object.keys(paramsObj).forEach((key) => {
                endpoint += `&${key}=${encodeURIComponent(paramsObj[key])}`;
            });

            const method = requestItem.method || 'GET';
            let body = method === 'PATCH' || method === 'POST' ? requestItem.body || {} : null;

            return { endpoint, method, body };
        });
        return this.http.post(`${API_URL}/bulk/`, endpoints).pipe(
            map((results: any) => {
                return results.map((result, index) => {
                    const response = result.response;
                    if (response) {
                        if (params[index].requestType === 'any') return response;

                        if (response.data) {
                            let result: any | any[] = response.data;

                            result =
                                result instanceof Array
                                    ? this.refineList(response, {
                                          entityType: params[index].entityType,
                                      })
                                    : this.makeupEntity(response.data as SrvEntData);

                            result = this.addMetaInfo(result, response.meta);
                            if (response.included) {
                                result.included = response.included.map((includedItem) =>
                                    this.makeupEntity(includedItem),
                                );
                            }
                            return result;
                        } else return null;
                    } else return null;
                });
            }),
        );
    }

    generateErrorMessage(error) {
        let messages = [];
        error?.error?.errors
            ?.filter((e) => !!e)
            .forEach((error) => {
                let message = error.status;
                message += error.code ? ` ${error.code}` : '';
                message += error.detail ? ` ${error.detail}` : '';
                message += error.source && error.source.pointer ? ` ${error.source.pointer}` : '';
                messages.push(message);
            });
        return messages.join('. ');
    }

    generateEntityBody(entityType, entityId, attributeData, relationshipsData = {}) {
        return {
            data: {
                id: entityId,
                type: entityType,
                attributes: {
                    ...attributeData,
                },
                relationships: { ...relationshipsData },
            },
        };
    }

    getTaskResult$(taskId) {
        const observable = this.http.get(`${API_URL}/bg/task/${taskId}/`);
        return timer(0, 3000).pipe(
            switchMap(() => observable),
            filter(
                (v: any) =>
                    !!v?.data?.attributes?.result ||
                    !!v?.data?.attributes?.error ||
                    v?.data?.attributes?.state === 'done',
            ),
            map(
                (result) =>
                    result.data.attributes?.result || { error: result?.data?.attributes?.error },
            ),
            take(1),
        );
    }

    private _getLangParam() {
        return `lang=${this.translocoService.getActiveLang()}`;
    }
}
