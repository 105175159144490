import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { LocaleService } from '@bazis/shared/services/locale.service';
import { DATETIME_LOCALE } from '@app/configuration.service';

@Pipe({
    name: 'localDate',
    pure: false,
})
export class LocalDatePipe implements PipeTransform {
    constructor(private localeService: LocaleService) {}

    transform(value: any, format: string, isUtc = false, locale = this.localeService.locale) {
        if (!value) {
            return '';
        }

        let finalFormat = DATETIME_LOCALE[locale][format] || format || 'shortDate';
        const timezone = isUtc ? '+00:00' : '';
        return formatDate(value, finalFormat, locale, timezone);
    }
}
