import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    QueryList,
    ViewChild,
} from '@angular/core';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { tap } from 'rxjs';

@Component({
    selector: 'bazis-slider',
    templateUrl: './slider.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderComponent implements AfterViewInit {
    @ViewChild('sliderContainer', { static: true }) set contentWrapEl(el: ElementRef) {
        if (!el) return;

        this.wrapper = el.nativeElement;
    }

    @ContentChildren('slide') list: QueryList<ElementRef>;

    @HostListener('window:resize') onResize() {
        if (this.list.length) {
            this.init(this.list.toArray());
        }
    }

    // корректировка для сдвига, если по макетам часть следующего слайда
    // находится в области видимости ширины контейнера
    @Input() correctX: number = 0;

    // расстояние между слайдами
    @Input() gapSlide: number = 0;

    @Input() hasIndicator: boolean = false;

    @Input()
    iconPrev: string = 'arrow-left';

    @Input()
    iconNext: string = 'arrow-right';

    @Input()
    typeButton: 'solid' | 'clear' | 'outline' = 'outline';

    @Input()
    sizeButton: 'xs' | 'small' | 'default' | 'large' = 'default';

    @Output()
    resized = new EventEmitter();

    wrapper;

    currentSwipe = 0;

    isEndSlider = false;

    allSlidesWidth: number = 0;

    containerWidth: number;

    shift: number = 0;

    list$;

    swipesCounter = new TemplateObservable(0);

    constructor() {}

    ngAfterViewInit(): void {
        if (this.list.length) {
            this.init(this.list.toArray());
        }

        this.list$ = this.list.changes.pipe(tap((list) => this.init(list)));
    }

    init(list) {
        let listWidth = 0;

        list.forEach((slide) => {
            listWidth = listWidth + slide.nativeElement.clientWidth;
        });
        this.allSlidesWidth = listWidth + list.length * this.gapSlide;

        this.containerWidth = this.wrapper.parentNode.clientWidth;

        if (this.containerWidth) {
            this.swipesCounter.set(
                Math.ceil((this.allSlidesWidth - this.correctX) / this.containerWidth),
            );
        }
    }

    slideStart() {
        this.shift = this.shift + this.containerWidth - this.correctX;
        if (this.currentSwipe !== 0) this.currentSwipe--;
        this.shiftCorrect();
    }

    slideEnd() {
        this.shift = this.shift - this.containerWidth + this.correctX;
        if (this.currentSwipe < this.swipesCounter._ - 1) ++this.currentSwipe;
        this.shiftCorrect();
    }

    shiftCorrect() {
        if (this.shift <= this.containerWidth - this.allSlidesWidth - this.correctX) {
            this.shift = this.containerWidth - this.allSlidesWidth - this.correctX;
            this.isEndSlider = true;
        } else {
            this.isEndSlider = false;
        }
        if (this.shift > 0) this.shift = 0;
    }
}
