import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalService } from '@bazis/shared/services/modal.service';
import { EntData } from '@bazis/shared/models/srv.types';
import { MediaQueryService } from '@bazis/shared/services/media-query.service';
@Component({
    selector: 'app-signatures',
    templateUrl: './signatures.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignaturesComponent {
    @Input() signInfo: EntData;

    @Input() document: EntData;

    @Input() documentName: string;

    @Input() site: string = location.host;

    isScrollingContent: boolean = false;

    openDropdownMenu = false;

    constructor(public mqSrv: MediaQueryService, public modalService: ModalService) {}

    ngOnInit() {
        console.log(this.signInfo);
    }

    onContentScroll(ev) {
        const scrollPosition = ev.target.scrollTop;
        this.isScrollingContent = scrollPosition > 50 ? true : false;
    }
}
