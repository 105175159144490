<ng-container *ngIf="{ tablist: tablist$ | async } as data"> </ng-container>
<div
    class="bazis-tabs__wrapper"
    [style.margin-right]="containerWidth < allTabsWidth ? '91px' : 0"
>
    <div
        class="bazis-tabs__container"
        #tabsContainer
        [style.transform]="'translateX(' + shift + 'px)'"
        swipe
        (swipeLeft)="slideStart()"
        (swipeRight)="slideEnd()"
    >
        <ng-content></ng-content>
    </div>
</div>

<div
    class="bazis-btn-group bh-hide-sm-down"
    *ngIf="containerWidth < allTabsWidth"
>
    <bazis-button
        [disabled]="shift === 0"
        (click)="slideStart()"
    >
        <bazis-icon name="angle-left"></bazis-icon>
    </bazis-button>
    <bazis-button
        [disabled]="isEnd"
        (click)="slideEnd()"
    >
        <bazis-icon name="angle-right"></bazis-icon>
    </bazis-button>
</div>
