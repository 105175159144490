import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SliderComponent } from '@bazis/shared/components/slider/slider.component';

@Component({
    selector: 'bazis-gallery-slider',
    templateUrl: './gallery-slider.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GallerySliderComponent extends SliderComponent implements OnInit {
    ngOnInit() {
        // this.iconPrev = 'angle-small-left';
        // this.iconNext = 'angle-small-right';
    }
}
