<div
    *transloco="let t"
    class="bazis-modal-wrap"
>
    <div
        *ngIf="titleKey"
        class="bazis-modal__header"
    >
        <h1>{{ t('titleKey') }}</h1>
    </div>
    <div
        class="bazis-modal__content bazis-cckeditor"
        [innerHTML]="message"
    ></div>
    <div class="bazis-modal__footer">
        <bazis-toolbar>
            <bazis-button
                slot="end"
                color="action"
                (click)="closeModal()"
            >
                {{ t('action.close') }}
            </bazis-button>
        </bazis-toolbar>
    </div>
</div>
