import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'bazis-status',
    template: `
        <div #reference>
            <bazis-chip
                class="bazis-status"
                [color]="color"
                [size]="size"
                [hasDecor]="hasDecor"
                [fill]="fill"
            >
                <span>{{ label }}</span>
            </bazis-chip>
            <bazis-tooltip
                [reference]="reference"
                [eventType]="'hover'"
                placement="top"
                [isStatic]="isStaticTooltip"
                class="bh-popper-z-index"
            >
                {{ label }}
            </bazis-tooltip>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusComponent {
    @Input() label: string;

    @Input() color: string;

    @Input() size: 'small' | '' = '';

    @Input() hasDecor: boolean = false;

    @Input() fill?: 'clear' | 'outline' | 'solid';

    @Input()
    isStaticTooltip: boolean = false;

    constructor() {}
}
