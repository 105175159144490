import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { Modal, ModalOptions } from '@bazis/shared/models/modal.types';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    private _defaultParams: ModalOptions = {
        component: null,
        componentProperties: null,
        hasCloseIcon: true,
        hasBackdrop: true,
        backdropClose: true,
        keyboardClose: true,
        modalType: 'default',
        cssClass: '',
        id: '',
    };

    modals = new TemplateObservable([]);

    constructor() {}

    create(params) {
        params = {
            ...this._defaultParams,
            ...params,
            id: params.id || uuidv4(),
        };

        const modal: Modal = {
            options: params,
            onDidDismiss: () => {
                return new Promise((resolve) => {
                    modal.dismiss = resolve;
                });
            },
        };

        const modals = this.modals._;
        modals.push(modal);

        this.modals.set(modals);

        return modal;
    }

    dismiss(result = null, modal = null) {
        let modals = this.modals._;
        if (!modal && modals.length > 0) modal = modals[modals.length - 1];

        if (!modal) return;
        if (modal.dismiss) modal.dismiss(result);
        modals = modals.filter((v) => v.options.id !== modal.options.id);
        this.modals.set(modals);
    }
}
