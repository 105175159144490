import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { mergeMap, Observable, throwError } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { DocumentService } from '../shared/services/document.service';
import { gatherBulkErrors } from '@bazis/utils';

@Injectable()
export class SignInterceptor implements HttpInterceptor {
    constructor(private documentService: DocumentService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((initialError) => {
                const error = gatherBulkErrors(initialError) || initialError;

                if (error instanceof HttpErrorResponse && error.status === 422) {
                    const signatureErrors = error.error?.errors.filter(
                        (apiError) => apiError?.code === 'USER_SIGNATURE_REQUIRED',
                    );
                    if (!signatureErrors || signatureErrors.length === 0) {
                        return throwError(() => error);
                    }

                    console.warn(`SIGN INTERCEPTOR:`, signatureErrors, error.error);

                    return this.documentService
                        .createGroupToSignBySettings(
                            signatureErrors.map((apiError) => {
                                return {
                                    entityId: apiError.source.id,
                                    entityType: apiError.source.type,
                                    contextLabel: apiError.meta
                                        ? apiError.meta.context_label
                                        : null,
                                };
                            }),
                            false,
                        )
                        .pipe(
                            filter((remaining: []) => !remaining || !remaining.length),
                            mergeMap((remaining) =>
                                remaining ? next.handle(request) : throwError(null),
                            ),
                        );
                }
                return throwError(error);
            }),
        );
    }
}
