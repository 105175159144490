<ng-container *transloco="let t">
    <bazis-button fill="clear"
        class="bazis-modal__close"
        (click)="closeModal()"
    >
        {{ t('action.close') }}
        <bazis-icon name="cross" slot="end"></bazis-icon>
    </bazis-button>

    <div class="bazis-modal__content">
        <ng-container *ngFor="let img of gallery; let i = index">
            <ng-container *ngIf="i === currentIndex">
                <ng-container *ngIf="img.id | entity: img.type | async as image">
                    <img
                        [src]="image.$snapshot.file"
                        [alt]="image.$snapshot.name"
                    />
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    <bazis-button fill="outline"
        *ngIf="currentIndex > 0"
        class="bazis-btn-prev"
        (click)="next(-1)"
    >
        <bazis-icon name="angle-small-left" slot="icon-only"></bazis-icon>
    </bazis-button>
    <bazis-button fill="outline"
        *ngIf="currentIndex < gallery.length - 1"
        class="bazis-btn-next"
        (click)="next(1)"
    >
        <bazis-icon name="angle-small-right" slot="icon-only"></bazis-icon>
    </bazis-button>
</ng-container>
