<span #tooltip>
    <bazis-icon
        part="icon"
        [size]="iconSize"
        [icon]="icon"
    ></bazis-icon>
    <bazis-tooltip
        part="tooltip"
        [reference]="tooltip"
        [eventType]="tooltipEventType"
        [placement]="tooltipPlacement"
        [offset]="tooltipOffset"
        [isStatic]="tooltipIsStatic"
        [innerHTML]="tooltipText"
    ></bazis-tooltip>
</span>
