import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'bazis-toolbar',
    template: `
        <div part="start">
            <slot name="start"></slot>
        </div>
        <div part="end">
            <slot name="end"></slot>
        </div>
    `,
    styleUrls: ['toolbar.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class ToolbarElement implements OnInit {
    @Input() direction: 'row' | 'column' = 'row';

    @Input() outline: boolean;

    @Input() align: 'top' | 'bottom' | 'center' | 'baseline' = 'center';

    @HostBinding('class') get toggleStyle() {
        return {
            'bazis-toolbar_outline': this.outline,
            'bazis-toolbar_column': this.direction === 'column',
            'bazis-toolbar_top': this.align === 'top',
            'bazis-toolbar_bottom': this.align === 'bottom',
            'bazis-toolbar_baseline': this.align === 'baseline',
        };
    }

    constructor() {}

    ngOnInit(): void {
        this.outline = this.outline !== undefined && this.outline !== false;
    }
}
