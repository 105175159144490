<ng-container *transloco="let t">
    <div
        class="bh-flex"
        *ngIf="signatureId | entity : 'document.signature' | async as signatureEntity"
    >
        <span
            class="sl-e-sign"
            color="primary"
        >
            <bazis-icon
                size="s"
                src="/assets/icons/other/e-sign.svg"
            ></bazis-icon>
        </span>
        <div>
            <h4>{{ signatureEntity.$snapshot.subject?.CN }}</h4>

            <dl class="bazis-dl_inline">
                <dt>{{ t('document.modal.organization') }}</dt>
                <dd>{{ signatureEntity.$snapshot.subject.O }}</dd>
                <dt>{{ t('document.modal.signDate') }}</dt>
                <dd>{{ signatureEntity.$snapshot.dt_created | localDate : 'shortDateTime' }}</dd>
                <dt>{{ t('document.modal.certificate') }}</dt>
                <dd>{{ signatureEntity.$snapshot.cert_serial }}</dd>
                <dt>{{ t('document.modal.certificateDates') }}</dt>
                <dd>
                    {{
                        t('document.modal.certificateDatesExpression', {
                            from:
                                signatureEntity.$snapshot.dt_actual_start
                                | localDate : 'shortDateTime',
                            to:
                                signatureEntity.$snapshot.dt_actual_finish
                                | localDate : 'shortDateTime'
                        })
                    }}
                </dd>
                <dt>{{ t('document.modal.issuerOrganization') }}</dt>
                <dd>{{ signatureEntity.$snapshot.issuer?.O }}</dd>
            </dl>
            <bazis-link
                borderStyle="none"
                (click)="downloadSign(signatureEntity.$snapshot.signature)"
            >
                <bazis-icon
                    slot="start"
                    name="download"
                    size="s"
                ></bazis-icon>
                {{ t('document.action.downloadSignature') }}
            </bazis-link>
        </div>
    </div>
</ng-container>
