<ng-container *ngIf="assembly.production">
    <!-- Yandex.Metrika counter -->
    <noscript
        ><div
            ><img
                src="https://mc.yandex.ru/watch/93017534"
                style="position: absolute; left: -9999px"
                alt="" /></div
    ></noscript>
    <!-- /Yandex.Metrika counter -->
</ng-container>
<div
    [ngClass]="'ua-' + deviceService.browser"
    [ngClass]="'os-' + deviceService.os"
    [ngClass]="'dt-' + deviceService.deviceType"
>
    <router-outlet></router-outlet>
    <bazis-modals></bazis-modals>
    <bazis-alert></bazis-alert>
    <bazis-toast></bazis-toast>
    <app-global-loader></app-global-loader>
</div>
