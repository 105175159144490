import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AlertService } from '@bazis/shared/services/alert.service';

@Component({
    selector: 'bazis-alert',
    templateUrl: './alert.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent implements OnDestroy, OnInit {
    @HostListener('document:keydown.escape') onKeydownHandler() {
        this.dismiss();
    }

    constructor(public alertService: AlertService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    buttonClick(button) {
        this.dismiss(button.action);
    }

    dismiss(action = null) {
        this.alertService.dismiss(action);
    }
}
