<div
    *transloco="let t"
    class="bazis-signed-file-inline"
>
    <div class="bazis-file-inline">
        <bazis-badge
            color="danger"
            size="medium"
        >
            {{ extention }}
        </bazis-badge>
        <span
            *ngIf="srcFile"
            class="bazis-list bazis-list_short-info"
        >
            <span class="bazis-item">
                <bazis-link
                    class="bazis-file-inline__file"
                    borderStyle="none"
                    [href]="hasLink ? srcFile : undefined"
                    [target]="hasLink ? '_blank' : undefined"
                    [title]="
                        t(fileNameKey, fileNameParams) || filesData.displayDocument.$snapshot.name
                    "
                >
                    {{ t(fileNameKey, fileNameParams) || filesData.displayDocument.$snapshot.name }}
                </bazis-link>
            </span>
            <span
                *ngIf="showFileSize"
                class="bazis-item"
            >
                <span>
                    {{ filesData.displayDocument.$snapshot.size | fileSize }}
                </span>
                <span
                    *ngIf="tooltipKey"
                    #tooltipTarget
                >
                    &nbsp;
                    <bazis-icon
                        name="interrogation"
                        size="xs"
                    ></bazis-icon>
                    <bazis-tooltip
                        [reference]="tooltipTarget"
                        placement="top-end"
                        [isStatic]="false"
                        [offset]="[8, 4]"
                        class="bh-popper-z-index"
                    >
                        {{ t(tooltipKey, tooltipParams) }}
                    </bazis-tooltip>
                </span>
            </span>
        </span>
    </div>

    <!-- show signatures if they exist in file entity and required by parent component -->
    <ng-container *ngIf="showSignatures && filesData.signInfo.$snapshot.signatories?.length > 0">
        <bazis-button
            fill="clear"
            size="small"
            class="bh-hide-xl-down"
            (click)="openSignatureDetails()"
        >
            <bazis-icon
                slot="icon-only"
                src="/assets/icons/other/key.svg"
            ></bazis-icon>
        </bazis-button>
        <bazis-button
            size="small"
            fill="clear"
            class="bh-hide-xl-down"
        >
            <bazis-link
                slot="icon-only"
                borderStyle="none"
                [href]="filesData.displayDocument.$snapshot.file_upload_url"
                [download]="filesData.displayDocument.$snapshot.name"
                target="_blank"
            >
                <bazis-icon name="download"></bazis-icon>
            </bazis-link>
        </bazis-button>

        <div class="bazis-dropdown bh-hide-xl-up">
            <bazis-button
                class="bazis-dropdown__trigger"
                fill="clear"
                size="small"
                (click)="toggleMenu($event)"
            >
                <bazis-icon
                    slot="icon-only"
                    name="menu-dots"
                    size="s"
                ></bazis-icon>
            </bazis-button>

            <div
                *ngIf="openDropdownMenu"
                class="bazis-dropdown__body"
            >
                <div class="bazis-list bazis-list_control-dd">
                    <bazis-button
                        class="bazis-item"
                        (click)="openSignatureDetails()"
                    >
                        {{ t('action.openSignatures') }}
                    </bazis-button>
                    <a
                        [href]="filesData.displayDocument.$snapshot.file_upload_url"
                        [download]="filesData.displayDocument.$snapshot.name"
                        target="_blank"
                        class="bazis-item"
                    >
                        {{ t('action.download') }}
                    </a>
                </div>
            </div>
        </div>
    </ng-container>
</div>
