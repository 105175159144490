<ng-container *ngIf="isPreview">
    <div
        class="bazis-gallery-preview"
        [ngClass]="cssClass"
    >
        <bazis-skeleton
            [animated]="true"
            [inMedia]="true"
        ></bazis-skeleton>
    </div>
</ng-container>
