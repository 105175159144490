<div
    *ngIf="typeSign !== 'none'"
    class="bazis-step__sign"
    part="sign"
    [class.bh-hidden]="signHidden"
>
    <bazis-icon
        *ngIf="typeSign === 'check'"
        name="check"
        size="s"
    ></bazis-icon>
    <slot name="sign"></slot>
</div>

<div
    class="bazis-step__label"
    part="label"
    [class.bazis-step__label_another]="signHidden"
>
    <slot></slot>
</div>
