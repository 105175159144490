<div
    *transloco="let t"
    class="bazis-empty"
    [ngClass]="cssClass"
>
    <img
        *ngIf="pathImg"
        [src]="pathImg"
        alt="empty content decor"
    />
    <h5 *ngIf="titleKey">
        {{ t(titleKey, titleParams) }}
    </h5>
    <p *ngIf="messageKey">
        {{ t(messageKey, messageParams) }}
    </p>
    <bazis-button *ngIf="buttonOptions"
        [fill]="buttonOptions.fill"
        [color]="buttonOptions.color"
        [size]="buttonOptions.size"
        (click)="addAction($event)"
    >
        <bazis-icon
            *ngIf="buttonOptions.iconStart"
            [icon]="buttonOptions.iconStart"
            slot="start"
        ></bazis-icon>
        <bazis-icon
            *ngIf="buttonOptions.iconEnd"
            [icon]="buttonOptions.iconEnd"
            slot="end"
        ></bazis-icon>
        {{ t(buttonOptions.titleKey) }}
    </bazis-button>
</div>
