<ng-container *ngIf="isPreview; else tplGallery">
    <div
        class="bazis-gallery-preview"
        *ngIf="imageEntities.length"
    >
        <div
            class="bazis-figure bh-pointer"
            *ngIf="imageEntities[0].id | entity: 'uploadable.file_upload' | async as image; else tplLoading"
            (click)="openGallery(imageEntities, 0)"
        >
            <img
                [src]="image.$snapshot.file"
                [alt]="image.$snapshot.title"
            />
        </div>
        <div
            class="bazis-gallery-preview__more"
            *ngIf="imageEntities.length > 1"
        >
            + {{ imageEntities.length - 1 }}
        </div>
    </div>
</ng-container>

<ng-template #tplGallery>
    <div class="bazis-list bazis-list_h bazis-list_gallery">
        <div
            class="bazis-item"
            *ngFor="let imageEntity of imageEntities; let i = index"
        >
            <div
                class="bazis-figure bh-pointer"
                *ngIf="imageEntity.id | entity: 'uploadable.file_upload' | async as image"
                (click)="openGallery(imageEntities, i)"
            >
                <img
                    [src]="image.$snapshot.file"
                    [title]="image.$snapshot.title"
                    [alt]="image.$snapshot.title"
                />
            </div>
        </div>
    </div>
</ng-template>

<ng-template #tplLoading>
    <bazis-gallery-skeleton
        [isPreview]="isPreview"
    ></bazis-gallery-skeleton>
</ng-template>
