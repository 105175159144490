import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'bazis-logo',
    template: `
        <img
            part="image"
            alt="Project Logo"
            [attr.src]="src"
        />
    `,
    styleUrls: ['logo.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class LogoElement implements OnInit {
    @Input() short: boolean;

    @Input() type: 'light' | 'dark' = 'dark';

    @Input() grayscale: boolean;

    src: string;

    constructor() {}

    ngOnInit(): void {
        this.short = this.short !== undefined && this.short !== false;
        this.grayscale = this.grayscale !== undefined && this.grayscale !== false;

        this.src = '/assets/logos/on-' + this.type;

        if (this.short) {
            this.src = this.src + '-short';

            if (this.grayscale) {
                this.src = this.src + '-grayscale';
            }
        }

        this.src = this.src + '.svg';
    }
}
