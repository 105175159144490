<ng-container *ngIf="!download && !href && !target && !routerLink; else tplLink">
    <div
        class="bazis-btn-native"
        part="native"
    >
        <span
            class="bazis-btn__inner"
            part="inner"
        >
            <slot name="icon-only"></slot>
            <slot name="start"></slot>
            <span class="bazis-btn__text_wrapped">
                <slot></slot>
            </span>
            <slot name="end"></slot>
        </span>
    </div>
</ng-container>
<ng-template #tplLink>
    <a
        class="bazis-btn-native"
        part="native"
        [attr.href]="href"
        [attr.target]="target"
        [attr.download]="download"
    >
        <span
            class="bazis-btn__inner"
            part="inner"
        >
            <slot name="icon-only"></slot>
            <slot name="start"></slot>
            <span class="bazis-btn__text_wrapped">
                <slot></slot>
            </span>
            <slot name="end"></slot>
        </span>
    </a>
</ng-template>
