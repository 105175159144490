import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { catchError, delay, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import {
    combineLatest,
    from,
    merge,
    Observable,
    of,
    shareReplay,
    Subject,
    switchMap,
    withLatestFrom,
} from 'rxjs';
import { ModalService } from '@bazis/shared/services/modal.service';
import { CryptoService } from '@bazis/crypto/crypto.service';
import { EntData, EntDocumentSettings } from '@bazis/shared/models/srv.types';
import { DocumentService } from '@bazis/shared/services/document.service';
import { TemplateObservable } from '@bazis/shared/classes/template-observable';
import { AlertService } from '@bazis/shared/services/alert.service';
import { Router } from '@angular/router';
import { SHARE_REPLAY_SETTINGS } from '@app/configuration.service';
import { MediaQueryService } from '@bazis/shared/services/media-query.service';

type ItemToSign = EntDocumentSettings & {
    document$: Observable<EntData>;
};

@Component({
    selector: 'bazis-signing',
    templateUrl: './signing.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SigningComponent {
    constructor(
        private crypto: CryptoService,
        private modalService: ModalService,
        private documentService: DocumentService,
        private alertService: AlertService,
        private router: Router,
        public mqSrv: MediaQueryService,
    ) {}

    @Input() itemsToSign: TemplateObservable<EntDocumentSettings[]>;

    @Input() forceLoadEntitiesAfterSigning: boolean;

    itemsToSign$: Observable<ItemToSign[]>;

    pluginError = false;

    certificate;

    certificates$ = this.crypto.selectCertificate().pipe(
        tap((v) => {
            this.pluginError = false;
        }),
        catchError((e) => {
            this.pluginError = true;
            return of(e);
        }),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    selectedEntities = {};

    allItems = [];

    signNext$;

    remainToSign$;

    signed = {};

    currentStep = 1;

    startSigningEntity$ = new Subject();

    signEntity$ = this.startSigningEntity$.pipe(
        tap(() => {
            this.currentStep = 3;
        }),
        mergeMap(() => this.signEntity()),
        shareReplay(SHARE_REPLAY_SETTINGS),
    );

    showCertificatesBlock = true;

    shownDocs = {};

    filesIsUploaded = new TemplateObservable(0);

    ngOnInit() {
        this.itemsToSign$ = this.itemsToSign.$.pipe(
            tap((itemsToSign) => {
                this.allItems = itemsToSign;
            }),
            map((itemsToSign) => {
                if (!itemsToSign) return [];
                return itemsToSign.map((item) => {
                    const documentSource = item.document
                        ? of({ ...item.document })
                        : this.documentService.getSignDocument$(
                              item.entityType,
                              item.entityId,
                              item.contextLabel,
                              item.documentPayload,
                              item.cancelPreviousSignings,
                          );
                    return {
                        ...item,
                        openDropdownMenu: false,
                        document$: documentSource.pipe(
                            take(1),
                            delay(1),
                            tap((v) => {
                                this.filesIsUploaded.set(this.filesIsUploaded._ + 1);
                            }),
                            shareReplay({ bufferSize: 1, refCount: false }),
                        ),
                    };
                });
            }),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );

        this.signNext$ = merge(this.signEntity$, this.itemsToSign$).pipe(
            mergeMap(() => this.itemsToSign$),
            map((itemsToSign) =>
                itemsToSign.find(
                    (item) =>
                        !this.signed[
                            `${item.entityType}-${item.entityId}-${item.contextLabel || ''}`
                        ],
                ),
            ),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );

        this.remainToSign$ = merge(this.signEntity$, this.itemsToSign$).pipe(
            mergeMap(() => this.itemsToSign$),
            map((itemsToSign) => itemsToSign.length - Object.keys(this.signed).length),
            shareReplay(SHARE_REPLAY_SETTINGS),
        );
    }

    signEntity() {
        return this.itemsToSign$.pipe(
            switchMap((items) => combineLatest([...items.map((item) => item.document$)])),
            filter((documents) => documents.filter((v) => !!v).length === documents.length),
            mergeMap((documents) =>
                combineLatest([
                    ...documents.map((document) =>
                        this.documentService.getFileContentToSign(
                            document.$snapshot.file_upload_url,
                        ),
                    ),
                ]),
            ),
            filter((files) => files.filter((v) => !!v).length === files.length),
            mergeMap((files) =>
                combineLatest([
                    ...files.map((file) =>
                        from(this.crypto.getSignForDataWithCertificate(file, this.certificate)),
                    ),
                ]),
            ),
            withLatestFrom(this.itemsToSign$),
            mergeMap(([signatures, itemsToSign]) =>
                this.documentService.signEntityDocuments$(
                    signatures.map((signature, index) => {
                        return {
                            settings: {
                                entityType: itemsToSign[index].entityType,
                                entityId: itemsToSign[index].entityId,
                                contextLabel: itemsToSign[index].contextLabel,
                                withoutIncludedDocuments:
                                    itemsToSign[index].withoutIncludedDocuments,
                            },
                            signature,
                            signBodyPayload: itemsToSign[index].signBodyPayload || null,
                        };
                    }),
                    this.forceLoadEntitiesAfterSigning,
                ),
            ),
            withLatestFrom(this.itemsToSign$),
            tap(([result, itemsToSign]) => {
                itemsToSign.forEach((itemToSign) => {
                    this.signed[
                        `${itemToSign.entityType}-${itemToSign.entityId}-${
                            itemToSign.contextLabel || ''
                        }`
                    ] = true;
                });
                this.currentStep = Object.keys(this.signed).length === this.allItems.length ? 4 : 2;
                if (this.certificate) {
                    this.showCertificatesBlock = false;
                }
                if (this.currentStep === 4) {
                    this.openAlert();
                    this.close();
                }
            }),
            catchError((err) => {
                this.currentStep = 2;
                console.warn('signature error [!]', err);
                return of(err);
            }),
        );
    }

    sign() {
        this.startSigningEntity$.next(true);
    }

    close() {
        this.modalService.dismiss({ signed: this.signed });
    }

    changeDocVisibility(visibility, id) {
        if (visibility) {
            this.shownDocs[id] = true;
        } else {
            delete this.shownDocs[id];
        }
    }

    openAlert() {
        const headerKey = this.allItems.length > 1 ? 'crypto.docsSigned' : 'crypto.docSigned';
        const messageKey =
            this.allItems.length === 1 ? 'crypto.successDocSigned1' : 'crypto.successDocsSignedAll';

        const alert = this.alertService.create({
            icon: 'check',
            color: 'success',
            headerKey,
            messageKey,
            //messageParams,
            buttons: [
                {
                    titleKey: 'crypto.close',
                    handler: () => {
                        this.close();
                    },
                },
            ],
        });

        alert.onDidDismiss().then(() => {
            this.router.navigateByUrl(this.router.url.replace('/edit', ''), {
                replaceUrl: true,
            });
        });
    }
}
