import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    ElementRef,
    HostListener,
    QueryList,
    ViewChild,
} from '@angular/core';
import { tap } from 'rxjs';

@Component({
    selector: 'bazis-tabs-slider',
    templateUrl: './tabs-slider.component.html',
    styleUrls: ['tabs-slider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsSliderComponent implements AfterContentInit {
    // TODO: поверить работоспособность компонента.
    // Есть ощущение, что кнопки вперед и назад не отображаются.
    @HostListener('window:resize') onResize() {
        if (this.wrapper) {
            this.containerWidth = this.wrapper.parentNode.clientWidth;
            this.shiftCorrect();
        }
    }

    @ViewChild('tabsContainer', { static: true }) set contentWrapEl(el: ElementRef) {
        if (!el) return;

        this.wrapper = el.nativeElement;
    }

    @ContentChildren('tab') tabList: QueryList<ElementRef>;

    wrapper;

    allTabsWidth: number = 0;

    containerWidth: number;

    shift: number = 0;

    isEnd = false;

    tablist$;

    ngAfterContentInit(): void {
        this.tablist$ = this.tabList.changes.pipe(
            tap((list) => {
                let tabsListWidth = 0;
                list.forEach((tab) => {
                    tabsListWidth = tabsListWidth + tab.nativeElement.clientWidth;
                });
                this.allTabsWidth = tabsListWidth;

                this.containerWidth = this.wrapper.parentNode.clientWidth;
            }),
        );
    }

    slideStart() {
        this.shift = this.shift + this.containerWidth;
        this.shiftCorrect();
    }

    slideEnd() {
        this.shift = this.shift - this.containerWidth;
        this.shiftCorrect();
    }

    shiftCorrect() {
        if (this.shift <= this.containerWidth - this.allTabsWidth) {
            this.shift = this.containerWidth - this.allTabsWidth;
            this.isEnd = true;
        } else {
            this.isEnd = false;
        }
        if (this.shift > 0) this.shift = 0;
    }
}
