import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
} from '@angular/core';
import { ConfigurationService } from '@app/configuration.service';

@Component({
    selector: 'bazis-vehicle-number',
    templateUrl: './vehicle-number.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleNumberComponent implements OnDestroy, OnChanges {
    @Input()
    vehicleNumber: string;

    parts = [];

    constructor(private configurationService: ConfigurationService) {}

    ngOnDestroy(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        let end = 0;
        let group = this.configurationService.vehicleNumberMask;
        if ('vehicleNumberDisplay' in this.configurationService) {
            group = this.configurationService.vehicleNumberDisplay;
        }
        this.parts = group.map((maskGroup) => {
            const start = end;
            end += maskGroup.length;
            return (this.vehicleNumber || '').substring(start, end).toUpperCase();
        });
    }
}
