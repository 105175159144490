import {
    APP_INITIALIZER,
    NgModule,
    CUSTOM_ELEMENTS_SCHEMA,
    LOCALE_ID,
    ErrorHandler,
} from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ConfigurationService } from '@app/configuration.service';

import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localeEn from '@angular/common/locales/en';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslocoRootModule } from '@bazis/transloco-root.module';
import { HeaderInterceptor } from '@bazis/interceptors/header.interceptor';
import { AuthInterceptor } from '@bazis/interceptors/auth.interceptor';
import { HttpErrorsInterceptor } from '@bazis/interceptors/http-errors.interceptor';
import { SignInterceptor } from '@bazis/interceptors/sign.interceptor';

import { SharedModule } from '@shared/shared.module';
import { HttpTimeoutInterceptor } from '@bazis/interceptors/timeout-error.interceptor';
import { ConfirmOrgInfoInterceptor } from '@bazis/interceptors/confirm-org-info.interceptor';
import { LocaleService } from '@bazis/shared/services/locale.service';
import { LocalDatePipe } from '@bazis/shared/pipes/local-date.pipe';
import * as Sentry from '@sentry/angular-ivy';
import { isLocal } from '@bazis/utils';
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';

registerLocaleData(localeRu, 'ru');
registerLocaleData(localeEn, 'en');

export function loadConfiguration(configService: ConfigurationService) {
    return () => configService.loadConfiguration();
}

const interceptorProviders = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HeaderInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ConfirmOrgInfoInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: SignInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpTimeoutInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpErrorsInterceptor,
        multi: true,
    },
];
const sentryProviders = [];
if (!isLocal()) {
    sentryProviders.push({
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
            showDialog: false,
            logErrors: true,
        }),
    });
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        BrowserAnimationsModule,
        SharedModule,
        TranslocoRootModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            // enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            enabled: !isLocal(),
            registrationStrategy: 'registerWhenStable:30000',
        }),
        NgxMaskDirective,
        NgxMaskPipe,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfiguration,
            deps: [ConfigurationService],
            multi: true,
        },
        Title,
        {
            provide: LOCALE_ID,
            deps: [LocaleService],
            useFactory: (localeService) => localeService.locale,
        },
        ...interceptorProviders,
        ...sentryProviders,
        LocalDatePipe,
        provideEnvironmentNgxMask(),
    ],

    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
})
export class AppModule {}
