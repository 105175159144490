<ng-container *transloco="let t">
    <p class="bazis-contact__name">
        {{ contact.last_name }}&nbsp; {{ contact.first_name }}&nbsp;
        {{ contact.middle_name }}
    </p>
    <p
        class="bazis-contact__position"
        *ngIf="contact.position"
        >{{ contact.position }}</p
    >

    <p
        *ngIf="contact.email"
        class="bazis-contact__mail"
    >
        <a [href]="'mailto:' + contact.email">{{ contact.email }}</a>
    </p>
    <p
        *ngIf="contact.phone"
        class="bazis-contact__phone"
    >
        {{ contact.phone | phone }}
        <ng-container *ngIf="contact.phone_additional">
            , {{ t('title.phone_additional') }} {{ contact.phone_additional }}
        </ng-container>
    </p>
</ng-container>
