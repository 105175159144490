import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { copyMessageFn } from '@bazis/utils';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'bazis-coordinates',
    templateUrl: './coordinates.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoordinatesComponent implements OnDestroy, OnInit {
    @Input()
    point;

    tooltipKey$ = new BehaviorSubject('tooltip.copyCoordinate');

    tooltipParams: any = null;

    constructor() {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    copy(e) {
        // широта, долгота
        copyMessageFn(`${this.point.coordinates[1]}, ${this.point.coordinates[0]}`);

        this.tooltipKey$.next('tooltip.copied');
        e.preventDefault();
        e.stopImmediatePropagation();

        setTimeout(() => {
            this.tooltipKey$.next('tooltip.copyCoordinate');
        }, 5000);
    }
}
