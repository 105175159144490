<div
    scroll
    (scrollToBottom)="needNextPage$.next(true)"
    class="bazis-infinite-scroll-content"
    *transloco="let t"
>
    <ng-container
        *ngIf="{
            list: list$ | async
        } as data"
    >
        <ng-container *ngFor="let item of data.list; let i = index; trackBy: trackById">
            <ng-template
                [ngTemplateOutlet]="itemTpl"
                [ngTemplateOutletContext]="{ item: item }"
            ></ng-template>
        </ng-container>
        <div
            class="bazis-item bh-events-none"
            *ngIf="data.list?.length === 0"
        >
            {{ t(emptyListKey || 'title.emptyList') }}
        </div>
    </ng-container>
</div>
