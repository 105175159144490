import { Pipe, PipeTransform } from '@angular/core';
import { of } from 'rxjs';
import { EntityService } from '@bazis/shared/services/entity.service';

@Pipe({
    name: 'organization',
})
export class OrganizationPipe implements PipeTransform {
    constructor(private entity: EntityService) {}

    transform(id: string) {
        if (!id) return of(null);

        return this.entity.getOrganizationEntity$(id);
    }
}
