import { Pipe, PipeTransform } from '@angular/core';
import { EntityService } from '@bazis/shared/services/entity.service';
import { EntList } from '@bazis/shared/models/srv.types';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'entityList',
})
export class EntityListPipe implements PipeTransform {
    constructor(private entity: EntityService) {}

    // можно расширять до передачи каких-то фильтров
    transform(entityType: string) {
        return this.entity
            .getAllEntitiesList$(entityType)
            .pipe(map((entList: EntList) => (entList ? entList.list : entList)));
    }
}
