import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { convertMbToBytes, flipObject, latinizeStr } from '@bazis/utils';
import { IsActiveMatchOptions } from '@angular/router';
import { EntityService } from '@bazis/shared/services/entity.service';
import { MaskSettings } from '@bazis/form/models/form-element.types';

// default api prefix
export const API_URL = '/api/web/v1';

// language settings
export const AVAILABLE_LANGUAGES = ['ru'];
export const SELECTED_LANGUAGE =
    AVAILABLE_LANGUAGES.indexOf(localStorage.getItem('lang')) > -1
        ? localStorage.getItem('lang')
        : AVAILABLE_LANGUAGES[0];

// settings {[fron-lang-id]: back-lang-id } (for faq and legal docs)
export const API_DOC_LANGS_MAP = {
    ru: 'ru',
};
export const VAT = 20;

// datetime formats which are typical for API
export const API_DATETIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss+00:00';
export const API_DATETIME_FORMAT_Z = 'YYYY-MM-DD[T]HH:mm:ss[Z]';

// Date time format configuration (set up in standard angular date pipe format), shortDate is used in bazis
const BASIC_DATETIME_LOCALE = {
    shortDate: 'dd.MM.y',
    shortDateShortWeekDay: 'dd.MM.y, EEEEEE',
    shortDateMediumWeekDay: 'dd.MM.y, EEE',
    shortDateLongWeekDay: 'dd.MM.y, EEEE',
    shortDateTime: 'dd.MM.y, HH:mm',
    shortDateMonth: 'dd.MM',
    shortTimeDate: 'HH:mm, dd.MM.y',
    time: 'HH:mm',
    mediumDate: 'dd MMM y',
    mediumDateShortWeekDay: 'dd MMM y, EEEEEE',
    mediumDateTime: 'dd MMM y HH:mm',
    mediumCurrentDateTimeSeconds: 'dd MMM HH:mm:ss',
    mediumTimeDate: 'HH:mm, dd MMM y',
    mediumDateMonth: 'dd MMM',
    longDate: 'dd MMMM y',
    longDateTime: 'dd MMMM y, HH:mm',
    longDateShortWeekDayTime: 'dd MMMM y, EEEEEE, HH:mm',
    longTimeDate: 'HH:mm, dd MMMM y',
    longDateMonthShortWeekDay: 'dd MMMM, EEEEEE',
    longDateMonthYearShortWeekDay: 'dd MMMM y, EEEEEE',
    longDateMonth: 'd MMMM',
    longMonthYear: 'MMMM y',
    year: 'y',
    month: 'MM',
    mediumMonth: 'MMM',
    mediumMonthStandalone: 'LLL',
};
export const DATETIME_LOCALE = {
    ru: { ...BASIC_DATETIME_LOCALE },
    en: { ...BASIC_DATETIME_LOCALE },
};

// ROLES settings
export const ROLE = {
    empty: null,
    anonymous: '',
};
export const ROLE_REVERT = flipObject(ROLE);
export const ROLE_ACCOUNT_TYPE = {};
export const MOBILE_ROLES = [];
export const DISABLE_NOTIFICATIONS_ROLES = [];
export const ALL_SEARCH_MODELS = [];
export const ROLE_SEARCH_MODEL_IDS = {};

export const FORM_SAVE_BUTTON_SETTINGS = {
    titleKey: 'action.save',
};

export const UNDERCONSTRUCTION_TITLE = {
    section: 'title.sectionUnderconstruction', // for tabs which are underconstruction
};

export const DOCUMENT_LINKS = {
    cookiePolicy: '/legal-documents/cookie_policy',
    privacyPolicy: '/legal-documents/privacy_policy',
    userAgreement: '/legal-documents/site_rules',
};

// Auth settings
export const AUTH_PAGE_URL = '/login';
export const PERSONAL_INFO_PAGE_URL = '/profile/personal-info';
export const SUCCESS_LOGIN_PAGE = '/success-login';
export const SUCCESS_INVITE_PAGE = '/success-invite';

// tech settings
export const SHARE_REPLAY_SETTINGS = { bufferSize: 1, refCount: true };

export const MAX_FILE_SIZE = convertMbToBytes(10); // 10мб

export const VEHICLE_MASK_SETTINGS: Partial<MaskSettings> = {
    placeHolderCharacter: '_',
    validation: false,
    keepCharacterPositions: false,
    showMaskTyped: true,
    showTemplate: true,
    patterns: {
        V: {
            pattern: new RegExp('^[abekmhopctyxавекмнорстухABEKMHOPCTYXАВЕКМНОРСТУХ]'),
        },
        '0': {
            pattern: new RegExp('\\d'),
        },
    },
    outputTransformFn: (value) => latinizeStr(`${value}`.toUpperCase()),
    inputTransformFn: (value) => latinizeStr(`${value}`.toUpperCase()),
};

export const PHONE_PATTERN = /\+7[0-9]{10}/;
export const PHONE_MASK_SETTINGS: Partial<MaskSettings> = {
    pipeMask: '+7 000 000-00-00',
    mask: '000 000-00-00',
    prefix: '+7 ',
    placeHolderCharacter: '_',
    dropSpecialCharacters: [' ', '-', '+7'],
    validation: false,
    keepCharacterPositions: false,
    showMaskTyped: true,
    showTemplate: true,
    outputTransformFn: (value) => {
        return `+7${value}`;
    },
};

export const PHONE_INPUT_TRANSFORM_FN = (phone: string) =>
    phone.indexOf('+7') > -1 ? phone.split('+7').join('') : phone;

export const COMMON_DATE_MASK_SETTINGS = {
    keepCharacterPositions: false,
    dropSpecialCharacters: false,
    placeHolderCharacter: '_',
    showMaskTyped: true,
    clearIfNotMatch: true,
    validation: false,
};

export const SNILS_PATTERN = /[0-9]{11}/;
export const SNILS_MASK_SETTINGS = {
    mask: '000-000-000 00',
    keepCharacterPositions: false,
    placeHolderCharacter: '_',
    showMaskTyped: true,
    clearIfNotMatch: true,
    validation: false,
    dropSpecialCharacters: [' ', '-'],
    showTemplate: true,
};

export const BIC_PATTERN = /[0-9]{9}/;
export const RRC_PATTERN = /[0-9]{9}/;
export const INN_PATTERN = /^([0-9]{10}|[0-9]{12})$/;
export const BANK_ACCOUNT_PATTERN = /[0-9]{20}/;

// List settings
export const DEFAULT_LIST_LIMIT = 20;
export const ALL_LIST_LIMIT = 1000;

export const DEFAULT_LINK_ACTIVE_MATCH_OPTIONS: IsActiveMatchOptions = {
    queryParams: 'ignored',
    matrixParams: 'subset',
    fragment: 'exact',
    paths: 'subset',
};

export const HEADER_MENU = {
    [ROLE.anonymous]: [
        {
            titleKey: 'header.summary',
            url: '/analytics',
        },
        {
            titleKey: 'header.carParks',
            url: '/car-parks',
        },
        {
            titleKey: 'header.vehicles',
            url: '/vehicles',
        },
        {
            titleKey: 'header.calendar',
            url: '/',
        },
        {
            titleKey: 'header.reservations',
            url: '/',
        },
        {
            titleKey: 'header.drivers',
            url: '/',
        },
        {
            titleKey: 'header.more',
            url: '/',
            children: [
                {
                    titleKey: 'header.agreement',
                    url: '/',
                },
                {
                    titleKey: 'header.faq',
                    url: '/faq',
                },
            ],
        },
    ],
    [ROLE.empty]: [],
};
export const USER_MENU = [
    {
        titleKey: 'header.personalInfo',
        url: '/profile/personal-info',
        icon: 'portrait',
        excludeForRoles: [],
    },
    {
        titleKey: 'header.account',
        url: '/profile/account',
        icon: 'credit-card',
    },
    {
        titleKey: 'header.notifications',
        url: '/profile/notifications',
        icon: 'bell',
    },
    {
        type: 'roles',
        titleKey: 'header.enterAs',
        icon: 'arrow-right',
        excludeForRoles: [],
    },
    {
        titleKey: 'header.logout',
        url: '/logout',
        icon: 'power',
        excludeForRoles: [],
    },
];

const COMMON_FOOTER_MENU = [
    {
        titleKey: 'footer.personalInfo',
        url: '/profile/personal-info',
    },
    {
        titleKey: 'footer.profileSettings',
        url: '/profile/settings',
    },
];
export const FOOTER_MENU = {
    [ROLE.anonymous]: COMMON_FOOTER_MENU,
};

// Project constants
export const FAQ_TG_CHANNEL_LINK = 'TODO: tg link';

export const AVG_SPEED = 50;

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    constructor(private http: HttpClient, private entityService: EntityService) {}

    private _configuration = null;

    public loadConfiguration(): Observable<any> {
        document.documentElement.setAttribute('lang', SELECTED_LANGUAGE);
        return combineLatest([
            of({
                tiles: {
                    default: {
                        url: 'https://{s}.map.asu.big3.ru/street/{z}/{x}/{y}.png',
                        size: 256,
                    },
                },
                emptyInputValue: '-',
                defaultLocation: [56.116765, 47.242928],
                contacts: { email: 'info@pochta.ru', phone: '+7 (800) 220-0-220' },
                vehicleNumberMask: ['V', '000', 'VV', '000'],
            }),
        ]).pipe(
            tap(([config]) => {
                this._configuration = {
                    ...config,
                };
            }),
        );
    }

    get schemas(): any {
        return {};
    }

    get tiles(): any {
        return this._configuration?.tiles || null;
    }

    get mapDefaultSettings(): any {
        return this.getMapSettings('default');
    }

    get mapGreySettings(): any {
        return this.getMapSettings('grey');
    }

    get emptyInputValue(): any {
        return this._configuration?.emptyInputValue || null;
    }

    get contacts(): any {
        return this._configuration?.contacts || null;
    }

    get vehicleNumberMask(): any {
        return this._configuration?.vehicleNumberMask || null;
    }

    get defaultLocation(): any {
        return this._configuration?.defaultLocation || null;
    }

    getMapSettings(tileType = 'default') {
        if (!this._configuration?.tiles) return null;
        return {
            tile: this._configuration.tiles[tileType],
        };
    }
}
