<div class="sl-card-mobile">
    <div class="sl-card-mobile__header">
        <bazis-toolbar [align]="'top'">
            <div slot="start">
                <bazis-skeleton
                    [animated]="true"
                    height="16"
                    bottom="8"
                    width="40"
                ></bazis-skeleton>
                <bazis-skeleton
                    [animated]="true"
                    height="16"
                    width="150"
                ></bazis-skeleton>
            </div>
            <div slot="end">
                <div class="bazis-dropdown sl-dropdown_action">
                    <bazis-button
                        size="small"
                        fill="clear"
                        [disabled]="true"
                        class="bazis-dropdown__trigger"
                    >
                        <bazis-icon
                            slot="icon-only"
                            name="menu-dots"
                            size="s"
                        ></bazis-icon>
                    </bazis-button>
                </div>
                <bazis-button
                    size="small"
                    fill="clear"
                    [disabled]="true"
                >
                    <bazis-icon
                        slot="icon-only"
                        name="angle-small-down"
                    ></bazis-icon>
                </bazis-button>
            </div>
        </bazis-toolbar>
        <bazis-skeleton
            [animated]="true"
            width="100"
            top="8"
        ></bazis-skeleton>
    </div>
</div>
